/* .gallery-grid:after {
  content: "";
  display: block;
  clear: both;
}

.gallery-grid-sizer,
.gallery-grid-item {
  width: 20%;
}

.gallery-grid-item {
  float: left;
}

.gallery-grid-item img {
  display: block;
  max-width: 100%;
} */

.gallery-grid-item {
  overflow: hidden;
}

.gallery-grid-item img {
  width: 100%;
}

.pswp__custom-caption {
  background: rgba(0, 0, 0, 0.75);
  font-size: 16px;
  color: #fff;
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}
.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}
.hidden-caption-content {
  display: none;
}
